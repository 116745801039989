import React from 'react';

// External Components
import {
  GridWrapper,
  fullWidthMinusMargins,
  Heading,
  Box,
  Paragraph,
  Link,
  GridItem,
  FlexWrapper
} from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  ImageContainer,
  Hyperlink,
  GridSpacer,
  LinearGradient
} from 'components';

// Data
import { useFooterData } from './useFooterData';

// Cms Data
import { useKisBusinessContactData } from 'graphqlHooks';

// Assets
// import pattern from 'assets/patterns/wooden-pattern-mdl-haus-deutschland.jpg';
import pattern from 'assets/patterns/pattern-bg.png';

export const Footer = () => {
  const { title, image, hyperlink, cta, sitemap, socials, legal, copyright } =
    useFooterData();
  const { phone, contact_links, social_links } = useKisBusinessContactData();
  const socialLinks = [...social_links, ...contact_links];

  return (
    // Markup
    <ContentWrapper>
      <Image data={image} />
      <Headline data={title} />
      <Button data={hyperlink} />
      <Spacer position={0} />
      <CtaTitle data={cta.title} />
      <Phone {...phone[0]} />
      <CtaDescription
        data={{ seoTitle: cta.seoTitle, description: cta.description }}
      />
      <Spacer position={1} />
      <Sitemap {...sitemap} />
      <Socials title={socials.title} links={socialLinks} />
      <Legal links={legal.links} copyright={copyright} />
      <Spacer position={2} />
      <Background />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <GridWrapper
    as="footer"
    sx={{
      width: '100%',
      overflow: 'hidden'
    }}>
    {children}
  </GridWrapper>
);

const Image = ({ data }) => (
  <Box
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
      gridRow: '1/span 3',
      height: ['45rem', '64rem', '37.5rem', '32.4rem', '47.7rem', '57.6rem'],
      ml: [
        '-100%',
        '-50%',
        fullWidthMinusMargins[2],
        fullWidthMinusMargins[3],
        fullWidthMinusMargins[4],
        fullWidthMinusMargins[5]
      ],
      mr: fullWidthMinusMargins,
      overflow: 'hidden',
      position: 'relative'
    }}>
    <ImageContainer
      src={data.src}
      imageAlt={data.alt}
      sx={{
        height: ['100%', '100%', '43.5rem', '37rem', '54rem', '73rem'],
        width: '100%'
      }}
    />
    <LinearGradient />
  </Box>
);

const Headline = ({ data }) => (
  <Heading
    as="h2"
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      color: 'white',
      fontFamily: 'primary.normal',
      fontSize: ['2.8rem', '4.4rem', '3.2rem', '2.8rem', '4rem', '4.4rem'],
      fontFeatureSettings:
        "'salt' on, 'ss01' on, 'ss02' on, 'ss03' on, 'ss04' on, 'ss05' on, 'ss08' on, 'calt' off",
      gridColumn: [
        '1/13',
        '1/13',
        '1/span 13',
        '1/span 11',
        '1/span 11',
        '1/span 10'
      ],
      gridRow: 1,
      lineHeight: 1.25,
      mt: ['2.8rem', '4.8rem', '9.4rem', '4.6rem', '6.4rem', '15.9rem'],
      zIndex: 1,
      '& > span': {
        fontFamily: 'primary.bold'
      }
    }}
  />
);

const Button = ({ data }) => (
  <Hyperlink
    {...data}
    variant="secondary"
    sx={{
      gridColumnStart: 1,
      gridRow: 2,
      mt: ['3.2rem', '3.2rem', '2.4rem', '3.2rem', '5.4rem', '6.4rem'],
      zIndex: 1
    }}
  />
);

const CtaTitle = ({ data }) => (
  <Paragraph
    className="footer__cta-title"
    sx={{
      color: 'white',
      fontFamily: 'body.medium',
      fontSize: ['1.5rem', '1.6rem', '1.5rem', '1.5rem', '1.5rem', '1.4rem'],
      gridColumn: [
        '1/span 10',
        '1/span 5',
        '1/span 7',
        '1/span 7',
        '1/span 5',
        '1/span 4'
      ],
      gridRow: 5,
      lineHeight: 1,
      zIndex: 1
    }}>
    {data}
  </Paragraph>
);

const Phone = ({ link_to, link_text }) => (
  <Link
    className="footer__phone"
    href={link_to}
    rel="noreferrer noopener"
    sx={{
      color: 'white',
      gridColumn: [
        '1/13',
        '1/13',
        '1/span 12',
        '1/span 12',
        '1/span 11',
        '1/span 10'
      ],
      gridRow: [6, 6, 7, 7, 7, 6],
      fontFamily: 'primary.bold',
      fontSize: ['3rem', '4.8rem', '3.2rem', '3rem', '4rem', '4.8rem'],
      lineHeight: 1,
      mt: ['1.6rem', '1.2rem', 0, 0, 0, '2.6rem'],
      zIndex: 1
    }}>
    {link_text}
  </Link>
);

const CtaDescription = ({ data }) => (
  <Box
    className="footer__cta-description"
    sx={{
      gridColumn: [
        '1/13',
        '1/span 11',
        '1/span 11',
        '1/span 11',
        '1/span 11',
        '1/span 10'
      ],
      gridRow: [7, 7, 8, 8, 8, 7],
      lineHeight: 1.5,
      mt: ['4rem', '3.2rem', '3.8rem', '4rem', '2.8rem', '3.8rem'],
      zIndex: 1
    }}>
    <Heading
      as="h3"
      sx={{
        color: 'white',
        fontFamily: 'body.bold',
        fontSize: ['1.5rem', '1.6rem', '1.5rem', '1.5rem', '1.5rem', '1.6rem'],
        mb: ['1rem', '1.2rem', '1.4rem', '1rem', '1.2rem', '1.4rem'],
        lineHeight: 1.5
      }}>
      {data.seoTitle}
    </Heading>
    <Paragraph
      sx={{
        color: 'white',
        fontFamily: 'body.normal',
        fontSize: ['1.5rem', '1.6rem', '1.5rem', '1.5rem', '1.5rem', '1.6rem']
      }}>
      {data.description}
    </Paragraph>
  </Box>
);

const Sitemap = ({ title, links }) => (
  <ListWrapper
    className="footer__sitemap"
    sx={{
      gridColumn: [
        '1/span 5',
        '1/span 4',
        '15/span 4',
        '15/span 4',
        '16/span 3',
        '17/span 3'
      ]
    }}>
    <ListTitle data={title} />
    <LinksWrapper>
      {links.map((link) => (
        <NavigationLink key={link.to} variant="footer" to={link.to}>
          {link.text}
        </NavigationLink>
      ))}
    </LinksWrapper>
  </ListWrapper>
);

const Socials = ({ title, links }) => (
  <ListWrapper
    className="footer__socials"
    sx={{
      gridColumn: [
        '7/13',
        '6/span 5',
        '20/25',
        '20/25',
        '20/span 4',
        '21/span 3'
      ]
    }}>
    <ListTitle data={title} />
    <LinksWrapper>
      {links.map((link) => (
        <Link
          key={link.link_to}
          variant="footer"
          target="_blank"
          rel="noreferrer noopener"
          href={link.link_to}>
          {link.link_text}
        </Link>
      ))}
    </LinksWrapper>
  </ListWrapper>
);

const Legal = ({ links, copyright }) => (
  <Box
    className="footer__legal"
    sx={{
      gridColumn: [
        '1/13',
        '1/span 8',
        '1/span 12',
        '1/span 12',
        '1/span 8',
        '1/span 6'
      ],
      gridRow: [10, 10, 9, 9, 9, 9],
      mt: ['7.2rem', '8rem', '15.7rem', '8.3rem', '6.2rem', 0],
      zIndex: 1
    }}>
    <FlexWrapper
      sx={{
        gap: ['2.3rem', '1.8rem', '2.3rem', '2.3rem', '2.3rem', '4.8rem']
      }}>
      {links.map((link) => (
        <NavigationLink key={link.to} variant="footerSublinks" to={link.to}>
          {link.text}
        </NavigationLink>
      ))}
    </FlexWrapper>
    <Paragraph
      variant="links.footerSublinks"
      dangerouslySetInnerHTML={{
        __html: copyright
      }}
      sx={{
        cursor: 'default',
        mt: ['2.4rem', '2.3rem', '2.4rem', '2.4rem', '2.4rem', '2.7rem'],
        '& > span': {
          fontFamily: 'body.bold'
        }
      }}
    />
  </Box>
);

const Background = () => (
  <GridItem
    className="footer__background"
    sx={{
      bg: 'primary',
      background: (t) => `url(${pattern}), ${t.colors.primary}`,
      backgroundSize: 'cover',
      borderColor: 'secondary',
      borderStyle: 'solid',
      borderWidth: '1.3rem 0 0 0',
      gridRow: [
        '4/span 8',
        '4/span 8',
        '4/span 7',
        '4/span 7',
        '4/span 7',
        '4/span 7'
      ],
      mx: fullWidthMinusMargins
    }}
  />
);

// Reusable Components

const ListTitle = ({ data }) => (
  <Paragraph
    sx={{
      color: 'white',
      fontFamily: 'body.bold',
      fontSize: ['1.4rem', '1.6rem', '1.4rem', '1.4rem', '1.4rem', '1.4rem'],
      lineHeight: 1,
      zIndex: 1
    }}>
    {data}
  </Paragraph>
);

const ListWrapper = ({ children, sx, ...props }) => (
  <Box
    sx={{
      alignSelf: 'start',
      gridRow: [9, 9, '7/span 3', '7/span 3', '7/span 3', '5/span 4'],
      position: 'relative',
      zIndex: 1,
      ...sx
    }}
    {...props}>
    {children}
  </Box>
);

const LinksWrapper = ({ children }) => (
  <Box sx={{ display: 'grid', gap: '2.4rem', mt: '2.4rem' }}>{children}</Box>
);

const Spacer = ({ position }) => {
  const styles = [
    {
      gridRow: 4,
      height: ['8.8rem', '8.8rem', '8.8rem', '8.8rem', '8.8rem', '7.1rem']
    },
    {
      gridRow: [8, 8, 6, 6, 6, 8],
      height: ['7.2rem', '8rem', '1.6rem', '1.6rem', '1.6rem', '8.2rem']
    },
    {
      gridRow: [11, 11, 10, 10, 10, 10],
      height: ['6.6rem', '6.6rem', '5.2rem', '6.3rem', '7.4rem', '6.6rem']
    }
  ];

  return <GridSpacer sx={styles[position]} />;
};
